@import "../assets/config.scss";

.mat-dialog-container{box-shadow:0px 11px 15px -7px rgba(0, 0, 0, 0.2),0px 24px 38px 3px rgba(0, 0, 0, 0.14),0px 9px 46px 8px rgba(0, 0, 0, 0.12);background:#fff;color:rgba(0,0,0,.87)}
.mat-dialog-title{font:500 20px/32px Roboto, "Helvetica Neue", sans-serif;letter-spacing:normal}.mat-expansion-panel-header{font-family:Roboto, "Helvetica Neue", sans-serif;font-size:15px;font-weight:400}.mat-expansion-panel-content{font:400 14px/20px Roboto, "Helvetica Neue", sans-serif;letter-spacing:normal}
.cdk-overlay-container,.cdk-global-overlay-wrapper{pointer-events:none;top:0;left:0;height:100%;width:100%}.cdk-overlay-container{position:fixed;z-index:1000}.cdk-overlay-container:empty{display:none}.cdk-global-overlay-wrapper{display:flex;position:absolute;z-index:1000}.cdk-overlay-pane{position:absolute;pointer-events:auto;box-sizing:border-box;z-index:1000;display:flex;max-width:100%;max-height:100%}.cdk-overlay-backdrop{position:absolute;top:0;bottom:0;left:0;right:0;z-index:1000;pointer-events:auto;-webkit-tap-highlight-color:transparent;transition:opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);opacity:0}.cdk-overlay-backdrop.cdk-overlay-backdrop-showing{opacity:1}@media screen and (-ms-high-contrast: active){.cdk-overlay-backdrop.cdk-overlay-backdrop-showing{opacity:.6}}.cdk-overlay-dark-backdrop{background:rgba(0,0,0,.32)}.cdk-overlay-transparent-backdrop,.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing{opacity:0}.cdk-overlay-connected-position-bounding-box{position:absolute;z-index:1000;display:flex;flex-direction:column;min-width:1px;min-height:1px}.cdk-global-scrollblock{position:fixed;width:100%}

.modal-dialog,
.mat-dialog-container{
    padding: 25px;
}
.mat-dialog-container{
    border-radius: 5px !important;
}
.mat-dialog-title{
    margin: 0;
}
.mat-modal-content{
    background: #FFF;
}
.modal .modal-header{
    padding: 5px 10px 10px 2px;
    border-bottom: 1px solid #eee;
}
.modal .modal-header h5,
.mat-modal-header h5{
    text-align: left;
    font-size: 16px;
    color: map-get($colors,one);
    font-weight: 100;
    margin: 0;
    font-weight: 600;
}
.modal .modal-footer{
    padding: 10px 0 0 0;
    margin: 0;
}
.modal-content{
    border-radius: 10px;
    padding: 10px;
}
.modal .modal-body,
.mat-modal-content .mat-dialog-content{
    font-size: 14px;
}
.mat-modal-content .mat-dialog-content{
    padding: 10px;
}
.mat-dialog-actions{
    display: table;
    float: right;
}
.mat-modal-content .mat-dialog-actions:last-child{
    margin-bottom: 0 !important;
}
.mat-modal-content .mat-dialog-actions{
    padding: 0 !important;
}
.mat-modal-content .mat-dialog-actions button{
    padding: 10px 30px !important;
}
.modal .modal-dialog{
    z-index: 2;
}
.modal .overlay{
    background: #FFF;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 1;
    -moz-opacity: 0.85;
    -khtml-opacity: 0.85;
    opacity: 0.85;
}
.modal-body{
    padding: 15px 2px;
}
.modal-backdrop{
    background: #FFF !important;
    display: none;
}
.modal-opened{
    z-index: -10;
    position: relative;
}
/** DM **/
.dm-modal .dm-modal-title{
    margin: 0 0 10px 0;
    display: flex;
    align-items: center;
}
.dm-modal .dm-modal-title .icon{
    position: relative;
    right: -5px;
    top: -5px;
    cursor: pointer;
}
.dm-modal .dm-modal-title .icon i{
    color: map-get($colors,one);
    font-size: 30px;
}
.dm-modal .dm-modal-title span{
    font-size: 16px !important;
    font-family: map-get($fonts,two) !important;
    flex: 1;
    font-weight: 700;
    margin-top: -10px;
}
.dm-modal .dm-modal-title .close{ 
    cursor: pointer !important;
    position: absolute;
    top: -20px;
    right: -20px;
    width: 60px;
    height: 60px;
    border-radius: 100%;
    align-items: center;
    display: flex;  
    background-color:rgba(255, 255, 255, 0.8);
    opacity: 1 !important;
    box-shadow: 0px 0px 0px 5px rgba(255,255,255,0.40);  
}
.dm-modal .dm-modal-title .close img{
    display: table;
    margin: 0 auto;
    width: 15px !important;
}
.dm-modal .dm-modal-footer{
    border-top: 1px solid #eee;
    margin: 10px 0 0 0;
    padding: 10px 0 0 0;
}
.dm-modal .dm-modal-footer button{
    margin: 0 10px 0 0;
}
.cdk-global-overlay-wrapper {
    display: flex;
    position: absolute;
    z-index: 1000;
    overflow: auto;
    pointer-events: auto;  
    padding: 30px 0;
}
.cdk-overlay-pane{
    position: relative !important;
}
.dm-modal .dm-modal-content{
    display: table;
    width: 100% !important;
}
.mat-modal-info h3{
    margin: 10px 0 10px 5px;
    font-weight: bold !important;
}
.mat-modal-info h4{
    margin: 0 0 0 7px;
    font-weight: bold;
} 
.mat-dialog-container{
    border-radius: 0 !important; 
}
.modal-message-content p{
    margin: 0;
}

@media(max-width: 767px){

    .mat-dialog-container{
        padding: 10px 15px !important;
    }
    .cdk-overlay-pane{
        max-width: 95vw !important;
    }
}

