@import "./config.scss";
@import "material.scss";
@import "./btn.scss";
@import "datepicker.scss";
@import "./form.scss";  
@import "./modulos.scss";  
@import "./page.scss";  
@import "./galeria.scss";    
@import "./modais.scss";   
@import "./footer.scss";   
@import "./template.scss";  
@import "animateCustom.scss"; 

html, body { height: 100%; }
body{
    font-family: map-get($fonts,one); 
    font-size: 12px;
    font-weight: 400;
    color: map-get($colors,four);
    overflow-x: hidden !important;
}
figure{
    margin: 0;
}
input,button{
    outline: none !important;
}
ul{
    margin: 0;
}
@media (min-width: 1064px){
    .container {
        width: 1170px !important;
    }    
    
}
@media(max-width: 767px){

    header .lista-actions .text{
        display: none !important;
    }

}
@media(max-width: 480px){


    .hide-mobile{
        display: none !important;
    }
    html,body{
        overflow-x:hidden !important;
        -webkit-overflow-x:hidden !important;
        -ms-overflow-x:hidden !important;
        -moz-overflow-x:hidden !important;
    }
    
}