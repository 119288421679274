@import "config.scss";


.btn-green,.btn-white,.btn-two,.btn-three,.btn-blue{
    background: map-get($colors,five);
    display: flex;
    padding: 15px 25px;
    border-radius: 20px;
    align-items: center;
    text-decoration: none;
    color: #FFF;
    font-weight: 600;
    font-size: 12px;
    position: relative;
    border: 0;
    cursor: pointer;
}
.btn-green span,.btn-white span,.btn-blue span{
    margin-right: 20px;
}
.btn-green i,.btn-white i,.btn-blue i{
    font-size: 20px;
    position: absolute;
    right: 8px;
}
.btn-white{
    background: #FFF;
    color: map-get($colors,one);
}
.btn-one{
    background: map-get($colors,five);
    border-radius: 5px;
    padding: 10px 20px;
    color: #FFF;
    border: 0;
}
.btn-one:hover{
    color: #FFF;
}
.btn-two{
    background: map-get($colors,two) !important;  
    border-radius: 5px; 
    padding: 10px 20px;
    color: #FFF;
    border: 0;
}
.btn-two:hover{
    color: #FFF;
}
.btn-blue{
    background: map-get($colors,seven);
    padding: 12px 30px;
    color: #FFF;
    border: 0;
}
.btn-blue i{
    color: map-get($colors,three);
}
.btn-three{
    background: map-get($colors,one);
    border-radius: 5px;
    color: #FFF;
}
.btn-icon{
    display: flex;
    align-items: center;
}
.btn-icon i,.btn-icon img{
    font-size: 18px;
    margin: 0 10px 0 0;
}
.btn-green:hover{
    color: #FFF !important; 
}
button{
    cursor: pointer !important;
}
.btn-all{
    background: map-get($colors,one);
    border-radius: 30px;
    color: #FFF;
    display: flex;
    align-items: center;
    padding: 12px 30px;
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
}
.btn-all span{
    font-weight: 600;
    text-transform: uppercase;
    font-size: 11px;
}
.btn-all .icon{
    margin-left: auto;
}
.btn-all i{
    margin: 0;
    width: 7px; 
}
