@import "config.scss";

.center-flex{
    display: flex;
    align-items: center;
}
.module .module-title{
    margin: 0 0 30px 0;
}
.module .title,.modulo .title{
    font-size: 36px;  
    font-weight: 700;
    font-family: map-get($fonts,one);
    color: #000;
}
.module .subtitle,.modulo .subtitle{
    color: #000;
    font-size: 22px;
    font-weight: 400;
    font-family: map-get($fonts,one);
    margin: 10px 0 0 0;
}
.module h3{
    color: #353535;
    font-size: 18px;
    font-weight: 200;
    margin: 8px 0 0 0;
    font-family: map-get($fonts,three);
}
.module-content{
    position: relative;
}
.slide-navigate-item{
    background: transparent;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    -webkit-box-shadow: 0 0 8px 0 #B8B8B8;
    box-shadow: 0 0 8px 0 #B8B8B8;
    border-radius: 100%;
    position: absolute;
    top: 32%;
    cursor: pointer;
    border: 3px solid #FFF;
}
.slide-navigate-item .content{
    background: #FFF;  
    border-radius: 100%;
    width: 40px;
    height: 40px;
    display: flex;
    margin: 0 auto;
    align-items: center;
    -webkit-transition: all 0.5s 0s ease;
    -moz-transition: all 0.5s 0s ease;
    -o-transition: all 0.5s 0s ease;
    transition: all 0.5s 0s ease;
}
.slide-navigate-item:hover .content{
    background: map-get($colors,one);
    color: #FFF;
}
.slide-navigate-item:hover .content i{
    color: #FFF;
}
.slide-navigate-item.prev{
    left: -20px;
}
.slide-navigate-item.next{
    right: -20px;
}
.slide-navigate-item img,
.slide-navigate-item i{
    display: table;
    margin: 0 auto;
    color: #535353;
}
.slide-navigate-item.template-2.next{
    right: 0;
}
.slide-navigate-item.template-2.prev{
    left: 0;
}
.slide-navigate-item.template-2{
    width: 45px;
    height: 45px;
}
.slide-navigate-item.template-2 img{
    width: 7px;
}
.center{
    display: table;
    margin: 0 auto;
}
.z-index-1{
    position: relative;
    z-index: 1;
    display: block;
}
.z-index-2{
    position: relative;
    z-index: 2;
    display: block;
}
.page{
    padding: 50px 0 100px 0;
}
.page-title{
    margin: 0 0 20px 0;
}
.page h1{
    font-size: 30px;
    font-family: map-get($fonts,two);
    font-weight: 600;
}
.box-sombra{
    background: #FFF;
    box-shadow: 5px 5px 20px 0 #d4d4d4;
    padding: 20px;
    border-radius: 5px;
}
strong{
    font-weight: 700;
}
a{
    text-decoration: none !important;
    color: #353535;
}
.title-h3{
    font-size: 16px;
    font-weight: 700;
    margin: 0 0 10px 0;
}
.text-custom{
    font-size: 13px;
    line-height: 20px;
}
.linha{
    height: 1px;
    width: 100%;
    display: table;
    margin: 15px 0;
    background: #eee;
}
.breadcrumbs-content{
    background: #f7f7f7;
}
.linha-divisor{
    background: #ababab;
    height: 0.5px;
    width: 100%;
    display: table;
    margin: 15px auto;
    max-width: 1100px;   
}
.splide__arrow{ 
    width: 50px !important;
    height: 50px !important;
}
.grecaptcha-badge{
    display: none !important;
}
.page{
    min-height: 500px !important;
}
.splide__pagination__page.is-active{
    background: map-get($colors,one) !important;
    transform: scale(1.8) !important;
}

@media(max-width: 959px){

    header .menu-desktop{
        display: none !important;
    }
    header .logo{
        margin: 0 !important;
    }
    header .logo img{
        width: 200px;
    }
    header .topo-partial .li-item,
    header .topo-partial .divisor-circle
    {
        display: none !important;
    }


}
@media(max-width: 767px){

    .slide-navigate-item.prev{
        left: 0;
    }
    .slide-navigate-item.next{
        right: 0;
    }
    .slide-navigate-item{
        width: 45px;
        height: 45px;
        top: 40%;
    }
    .slide-navigate-item img{
        width: 8px;
    }
    .slick-padding-mobile .slick-list{
        padding: 10px 20px 10px 10px !important;
    }

} 

